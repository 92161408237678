<template>
  <b-container>
    <b-img src="../assets/atd-emblem-outline.svg" class="emblem" alt="ATD Machinery"></b-img>
    <center><b-img src="../assets/logo.jpg" alt="ATD Machinery" width="200px" class="mb-4"></b-img></center>
    <b-row align-v="center" align-h="center" v-if="!is_logged_in">
      <b-col cols="6">
        <h1>{{ $route.name }}</h1>
        <b-form @submit="onSubmit" @reset="onReset">
          <b-form-group id="input-group-1" label="Name" label-for="input-1">
            <b-form-input id="input-1" v-model="name" type="text" required></b-form-input>
          </b-form-group>
          
          <b-form-group id="input-group-2" label="Email" label-for="input-2">
            <b-form-input id="input-2" v-model="email" type="text" required></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-3" label="Message" label-for="input-3">
            <b-form-textarea id="input-3" v-model="message" type="text"></b-form-textarea>
          </b-form-group>

          <b-button variant="link" to="/" :disabled="loading">{{strings.backtologin}}</b-button>
          <b-button type="submit" variant="primary" :disabled="loading">{{strings.requestaccessbtn}} <font-awesome-icon v-if="loading" icon="spinner" spin /></b-button>

          <b-alert :show="showResponse" variant="success" dismissible class="mt-4">{{strings.accountrequested}}</b-alert>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'

export default {
  name: "Register",
  data() {
    return {
      name: '',
      email: '',
      message: '',
      showResponse: false
    }
  },
  computed: {
      ...mapState(['loading', 'strings']),
      ...mapGetters('auth', ['is_logged_in'])
    },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      axios.post('/wp-json/wp/v2/requestaccess', { name: this.name, email: this.email, message: this.message })
      .then(() => {
        this.showResponse = true
        // Reset our form values
        this.name = ''
        this.email = ''
        this.message = ''
      })
    },
    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.name = ''
      this.email = ''
      this.message = ''
    },

    requestaccess() {
      
    }
  },
  updated() {
  },
  mounted() {
  },
}
</script>