<template>
  <div>
    
    <b-row :cols="cols">
      <b-col v-for="(item, idx) in list" :key="idx" class="mb-4">
        <slot v-if="item.id > 0 && !loading" v-bind:item="item">{{item}}</slot>
        <slot v-else name="loading"><b-spinner /></slot>
      </b-col>
    </b-row>

    <b-pagination v-if="num_pages > 1"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="per_page"
      aria-controls="my-table"
    ></b-pagination>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PaginatedList",
  data() {
    return {
      per_page_options: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ]
    };
  },
  props: {
    cols: {
      type: Number,
      default: 3
    },
    module: {
      type: String,
      required: true,
    },
    meta_key: {
      type: String,
    },
    meta_value: {
      type: String,
    },
    author: {
      type: String,
    },
    status: {
      type: String,
    },
    options: {
      type: Object
    }
  },
  watch: {
    current_page() {
      this.get()
    },
  },
  computed: {
    list() {
      return this.$store.getters[this.module + '/current_page_list'];
    },
    total_items() {
      return this.$store.state[this.module].total_items
    },
    num_pages() {
      return this.$store.state[this.module].num_pages
    },
    per_page: {
      get() {
        return this.$store.state[this.module].per_page
      },
      set(value) {
        this.$store.commit(this.module + '/per_page', value)
        this.current_page = 1
        this.get()
      }
    },
    current_page: {
      get() {
        return this.$store.state[this.module].current_page
      },
      set(value) {
        this.$store.commit(this.module + '/current_page', value)
      }
    },
    ...mapState(["loading"]),
  },
  methods: {
    get() {
      var o = { page: this.current_page };
      if (this.meta_key) {
        o.meta_key = this.meta_key
        o.meta_value = this.meta_value
      }
      if (this.status) o.status = this.status
      if (this.author) o.author = this.author
      if (this.options) { o = Object.assign(o, this.options) }
      this.$store.dispatch(this.module + "/get", o);
    },
  },
  mounted() {
    this.get();
  },
};
</script>