import axios from "axios"
import Vue from "vue"

function create_unique_list(object, prop) {
  var values = []

  Object.values(object).forEach((m) => {
    if (values.indexOf(m[prop]) < 0 && m[prop] != "") {
      values.push(m[prop])
    }
  })
  
  values.sort()
  return values
}

const machines = {
  namespaced: true,

  state: () => ({
    list: [],
    dlcategories: [],
    machineCategory: null,
    machineCustomer: null,
    machineType: null
  }),
  getters: {
    machines: (state) => state.list,
    download_categories: (state) => {
      const catAr = Object.entries(state.dlcategories)
      catAr.sort(function(a, b) { 
        if (a[1].SortOrder < b[1].SortOrder) return -1
        else if (a[1].SortOrder > b[1].SortOrder) return 1
        else return 0
      })

      var tmp = new Array()
      catAr.forEach(cat =>  { 
        tmp.push(cat[1].Category)
      });
      
      return tmp
    },
    filtered: (state, getters, rootState, rootGetters) => {
      const machinesAr = Object.entries(state.list)
      const filtered = machinesAr.filter((machine) => {
        return (machine[1].Visibility == 1 || rootGetters['auth/is_admin']) && 
          (!state.machineCategory || machine[1].MachineCategory == state.machineCategory) && 
          (!state.machineCustomer || machine[1].CustomerID == state.machineCustomer) && 
          (!state.machineType || machine[1].MachineType == state.machineType)
      })

      filtered.sort(function(a, b) { 
        if (a[1].MachineDescription < b[1].MachineDescription) return -1
        else if (a[1].MachineDescription > b[1].MachineDescription) return 1
        else return 0
      })

      var items = Object.fromEntries(filtered)
      return items
    },
    categories: (state, getters, rootState, rootGetters) => {
      const machinesAr = Object.entries(state.list)
      const filtered = machinesAr.filter((machine) => {
        return (machine[1].Visibility == 1 || rootGetters['auth/is_admin']) && 
          // (!state.machineCategory || machine[1].MachineCategory == state.machineCategory) && 
          (!state.machineCustomer || machine[1].CustomerID == state.machineCustomer) && 
          (!state.machineType || machine[1].MachineType == state.machineType)
      })
      const list = Object.fromEntries(filtered)

      return create_unique_list(list, 'MachineCategory')
    },
    types: (state, getters, rootState, rootGetters) => {
      const machinesAr = Object.entries(state.list)
      const filtered = machinesAr.filter((machine) => {
        return (machine[1].Visibility == 1 || rootGetters['auth/is_admin']) && 
          (!state.machineCategory || machine[1].MachineCategory == state.machineCategory) && 
          (!state.machineCustomer || machine[1].CustomerID == state.machineCustomer)
          // (!state.machineType || machine[1].MachineType == state.machineType)
      })
      const list = Object.fromEntries(filtered)

      return create_unique_list(list, 'MachineType')
    },
    customers: (state, getters, rootState, rootGetters) => {
      const machinesAr = Object.entries(state.list)
      const filtered = machinesAr.filter((machine) => {
        return (machine[1].Visibility == 1 || rootGetters['auth/is_admin']) && 
          (!state.machineCategory || machine[1].MachineCategory == state.machineCategory) && 
          // (!state.machineCustomer || machine[1].CustomerID == state.machineCustomer) && 
          (!state.machineType || machine[1].MachineType == state.machineType)
      })
      const list = Object.fromEntries(filtered)

      return create_unique_list(list, 'CustomerID')
    },
    // download_categories: (state, getters) => (machine) => {
    //   return create_unique_list(getters.machines[machine]['Downloads'], 'Category')
    // },
    image_path: () => (filename) => axios.defaults.baseURL + '/PortalImages/' + filename,
    download_path: () => () => axios.defaults.baseURL + '/wp-json/wp/v2/download',
    pub_path: () => (filename) => axios.defaults.baseURL + '/wp-json/wp/v2/pub/' + filename
  },
  mutations: {
    list: (state, value) => state.list = value,
    dlcategories: (state, value) => state.dlcategories = value,
    machineCategory: (state, value) => state.machineCategory = value,
    machineCustomer: (state, value) => state.machineCustomer = value,
    machineType: (state, value) => state.machineType = value,
    add_machine: (state, data) => Vue.set(state.list, data.key, data.machine),
    remove_machine: (state, machineKey) => Vue.delete(state.list, machineKey)
  },
  actions: {
    async get({commit}) {
      return axios.get('/wp-json/wp/v2/machines')
      .then((response) => {
        if (response && response.status == 200) {
          commit('list', response.data)
        }
      })
      .catch(() => {})
    },
    async save({state, commit}) {
      return axios.post('/wp-json/wp/v2/machines', { data: state.list })
      .then((response) => {
        if (response && response.status == 200) {
          commit('list', response.data)
        }
      })
    },

    //TODO: Navigate to detail page of added machine to start editing
    add_machine({ commit, dispatch }) {
      let data = {
        key: Date.now(),
        machine:  {
          Cortonalink: "",
          CustomerID: "",
          Downloads: {},
          Image:"",
          MachineCategory:"",
          MachineDescription:"New Machine",
          MachineType:"",
          TechSpec:"",
          Visibility: 0
        }
      }
      commit('add_machine', data)
      
      dispatch('save')

      return data.key
    },

    clone_machine({ commit, dispatch }, machine) {
      let m = JSON.parse(JSON.stringify(machine))
      m.MachineDescription = machine.MachineDescription + " (Clone)"
      let data = { machine: m, key: Date.now()}
      commit('add_machine', data)

      dispatch('save')

      return data.key
    },

    remove_machine({ commit, dispatch }, machineKey) {
      commit('remove_machine', machineKey)
      
      dispatch('save')
    }
  },
}

export default machines