import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Machines from '../views/Machines.vue'
import Machine from '../views/Machine.vue'
import Partfinder from '../views/Partfinder.vue'
import Cart from '../views/Cart.vue'
import News from '../views/News.vue'
import NewsDetail from '../views/NewsDetail.vue'

const routes = [
  {
    path: '/',
    name: 'Machines',
    component: Machines,
  },
  {
    path: '/machine/:slug',
    name: 'MachineDetail',
    component: Machine,
  },
  {
    path: '/machine/:slug/partscatalog',
    name: 'Interactive Parts Catalog',
    component: Partfinder,
  },
  {
    path: '/news',
    name: 'News',
    component: News,
  },
  {
    path: '/news/:slug',
    name: 'NewsDetail',
    component: NewsDetail,
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Request access',
    component: Register,
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})
// TODO: Scroll to top fixen
router.beforeEach((to, from, next) => {
  if ((to.path != '/login' && to.path != '/register') && !store.getters['auth/is_logged_in']) {
    next({ path: '/login', query: { nextUrl: to.fullPath } })
  } else {
    window.scrollTo(0,0)
    if (to.name == 'NewsDetail') store.dispatch('news/get_item_by_slug', to.params.slug)

    next()
  }
})

export default router
