<template>
  <b-container>
    <b-img src="../assets/atd-emblem-outline.svg" class="emblem" alt="ATD Machinery"></b-img>
    <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
    <h1 class="title">{{ $route.name }}</h1>

    <b-row>
      <b-col>
        <paginatedlist module="news" cols="3">
          <!-- items are loading -->
          <template v-slot:loading>
            <b-card no-body class="h-100 card-news">
                <b-card-body class="text-center">
                  <b-spinner />
                </b-card-body>
            </b-card>
          </template>

          <!-- news item -->
            <template v-slot:default="{ item }">
              <b-card no-body class="card-news">
                <b-link :to="'/news/' + item.slug">
                  <div class="card-img">
                    <b-card-img-lazy top v-if="item.large_featured_image_url" :src="item.large_featured_image_url" />
                  </div>

                  <b-card-body>
                    <b-card-title v-html="item.title.rendered"></b-card-title>
                    <b-card-sub-title sub-title-text-variant="">{{ item.date |  formatDate}}</b-card-sub-title>
                    <div class="new" v-if="!item.read" sub-title-text-variant="">{{strings.new}}</div>
                    <b-card-text v-html="item.excerpt.rendered"></b-card-text>
                  </b-card-body>
                  <b-card-footer>
                    <b-button variant="primary" :to="'/news/' + item.slug">{{strings.readmore}}</b-button>
                  </b-card-footer>
                </b-link>
              </b-card>
            </template>

        </paginatedlist>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: "News",
  data() {
    return {
    }
  },
  computed: {
    breadcrumb() {
      return [{text: 'News', to: '/news'}]
    },
    ...mapState(['strings'])
  },
  methods: {
  },
  updated() {
  },
  mounted() {
  },
}
</script>