<template>
  <b-container>
    <b-img src="../assets/atd-emblem-outline.svg" class="emblem" alt="ATD Machinery"></b-img>
    <b-row v-if="item" class="justify-content-center">
      <b-col cols="8">
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
        <h1 class="title" v-if="item" v-html="item.title.rendered"></h1>
        <h5 class="subtitle">{{item.date | formatDate}}</h5>
        <b-img-lazy fluid v-if="item.large_featured_image_url" :src="item.large_featured_image_url" class="img-fluid featured-image" />
        <div v-html="item.content.rendered"></div>  
        <b-button class="mt-4" to="/news">{{strings.backtonews}}</b-button>
      </b-col>
    </b-row>

  </b-container>
</template>
<script>
import axios from 'axios';
import { mapGetters, mapState } from 'vuex';

export default {
  name: "NieuwsDetail",
  data() {
    return {
    }
  },
  computed: {
    // slug: function() { return this.$route.params.slug },
    item: function() { return this.item_by_slug(this.$route.params.slug) },

    breadcrumb() {
      return [
        { text: 'Home', to: '/home'},
        { text: 'News', to: '/news'},
        { html: this.item ? this.item.title.rendered : '...' , to: '/news/' + this.$route.params.id}
      ]
    },
    ...mapGetters('news', ['item_by_slug']),
    ...mapState(['loading', 'strings'])
  },
  watch: {
    item: function() {
      if (this.item && !this.item.read) {
      axios.get('/wp-json/wp/v2/posts/'+this.item.id+'/mark_as_read')
    }
    }
  },
  methods: {
  },
  mounted() {
  }

};
</script>