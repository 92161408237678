import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'
import authSrc from './auth-src';

// Transpiler for older browsers
import 'core-js/stable';
// optional but required for transforming generator fns.
import 'regenerator-runtime/runtime'; 


// Vue Bootstrap: https://bootstrap-vue.org/docs/components
import './main.scss' //custom variables
import './plugins/bootstrap-vue'

// Font Awesome: https://github.com/FortAwesome/vue-fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

Vue.use(authSrc)

moment.locale('nl_NL');
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('dddd DD MMMM YYYY')
  }
})
Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('HH:mm')
  }
})

// Our App
import App from './App.vue'
import router from './router'
import store from './store'

// Axios default interceptors
var pendingRequests = 0
axios.interceptors.request.use(
  //success handler
  function (config) {
    pendingRequests++
    if (!store.state.loading) {
      store.commit('loading', true)
    }
    return config;
  }, 
  function (error) {
    // store.commit('loading', false)
    // Do something with request error
    return Promise.reject(error);
  }
)
axios.interceptors.response.use(
  //success handler
  function (response) {
    if (pendingRequests > 0) {
      pendingRequests--
      if (pendingRequests == 0 && store.state.loading) {
        store.commit('loading', false)
      }
    }
    return response;
  }, 
  
  //error handler
  function (error) {
    if (pendingRequests > 0) {
      pendingRequests--
      if (pendingRequests == 0 && store.state.loading) {
        store.commit('loading', false)
      }
    }
    // Do something with response error
    return Promise.reject(error);
  }
)

// Custom Components
import navbar from './components/Navbar'
Vue.component('navbar', navbar)
import paginatedlist from './components/PaginatedList'
Vue.component('paginatedlist', paginatedlist)

Vue.config.productionTip = false

axios.defaults.withCredentials = true;
axios.defaults.baseURL = "//clientportal.atdmachinery.nl"

//
// function addToCart(oItem)
// {
// 	window.parent.postMessage(oItem, '*');
// }
window.addEventListener("message", (event) => {
  if ((event.origin == 'https://clientportal.atdmachinery.nl' || event.origin == 'http://clientportal.atdmachinery.nl') && event.data.dpl_item) {
    let machineKey = router.currentRoute.params.slug
    let machine = store.state.machines.list[machineKey]
    let data = event.data
    data.machinekey = machineKey
    data.machinedescription = machine.MachineDescription
    store.dispatch('orders/add_item_to_cart', data)
  }
}, false);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')