import axios from 'axios'
if (!axios) {
  throw new Error('[vue-auth-src] cannot locate Axios');
}

function setSrc(el, binding, tag) {
  if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
    var srcUrl = binding.value;
    var alt = el.alt
    el.alt = ""
    el.classList.add('loading')

    axios({
      method: 'get',
      url: srcUrl,
      responseType: tag == 'img' ? 'arraybuffer' : 'text'
    })
    .then(function(resp) {
      if (tag == 'img') {
        var mimeType = resp.headers['content-type'].toLowerCase();
        var imgBase64 = new Buffer(resp.data, 'binary').toString('base64');
        el.src = 'data:' + mimeType + ';base64,' + imgBase64;
        el.alt = alt
      } else if (tag == 'iframe') {
        el.contentWindow.document.write(resp.data)
      } else {
        console.log('unsupported tag ' + tag)
      }
      // el.classList.remove('loading')

    }).catch((function() {
      el.src = srcUrl;
      el.classList.remove('loading')
    }));
  }
}

export default {
  install(Vue) {
    Vue.directive('auth-src', {
      bind: function(el, binding, vnode) {
        setSrc(el, binding, vnode.tag);
      },
      componentUpdated: function(el, binding, vnode) {
        setSrc(el, binding, vnode.tag);
      }
    });
  }
};