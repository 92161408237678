<template>
  <b-container>
    <b-img src="../assets/atd-emblem-outline.svg" class="emblem" alt="ATD Machinery"></b-img>

    <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
    <h1 class="title">{{ $route.name }}</h1>

    <!-- TODO: https://bootstrap-vue.org/docs/components/form-input#datalist-support -->
    <b-form>
      <b-row>
        <b-col cols="12" lg="4">
          <b-form-group v-if="customers.length > 1" :label="strings.customer">
            <b-form-input list="machine-customer-list" v-on:input="filterByCustomer"
              :value="machineCustomer"></b-form-input>
            <datalist id="machine-customer-list">
              <option v-for="customer in customers" :key="customer.id">{{
                customer
              }}</option>
            </datalist>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="4">
          <b-form-group :label="strings.type">
            <b-form-input list="machine-type-list" v-on:input="filterByType" :value="machineType"></b-form-input>
            <datalist id="machine-type-list">
              <option v-for="type in types" :key="type.id">{{ type }}</option>
            </datalist>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="4">
          <b-form-group :label="strings.category">
            <b-form-input list="machine-category-list" v-on:input="filterByCategory"
              :value="machineCategory"></b-form-input>
            <datalist id="machine-category-list">
              <option v-for="category in categories" :key="category.id">{{
                category
              }}</option>
            </datalist>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- <b-form-group :label="strings.customer">
        <b-form-select v-model="machineCustomer" :options="customers">
          <template #first>
            <b-form-select-option :value="null">{{strings.filterby}} {{strings.customer}}</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group :label="strings.type">
        <b-form-select v-model="machineType" :options="types">
          <template #first>
            <b-form-select-option :value="null">{{strings.filterby}} {{strings.type}}</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group :label="strings.category">
        <b-form-select v-model="machineCategory" :options="categories">
          <template #first>
            <b-form-select-option :value="null">{{strings.filterby}} {{strings.category}}</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group> -->
    </b-form>
    <b-button variant="primary" class="mb-4" @click="addMachine()" v-if="is_admin && !loading"><font-awesome-icon
        icon="plus-square" /> Add machine</b-button>
    <b-spinner v-if="loading"></b-spinner>
    <template v-for="(machine, key) in filtered">
      <b-card :key="key" no-body class="card-machine" :class="{ 'machine-hidden': machine.Visibility == 0 }">
        <!-- <b-link> -->
        <b-card-body>
          <b-row align-v="center">
            <b-col col xl="2" lg="3" md="4" sm="5" cols="12">
              <!-- <b-col cols="3"> -->
              <b-link :to="'/machine/' + key" class="img-holder">
                <b-card-img-lazy class="mb-4" fluid :alt="machine.MachineDescription" :src="image_path(machine.Image)" />
              </b-link>
            </b-col>
            <b-col>
              <b-link :to="'/machine/' + key">
                <b-card-title>{{ machine.MachineDescription }}
                  <font-awesome-icon icon="eye-slash" v-if="machine.Visibility == 0" />
                </b-card-title>
                <ul>
                  <li>{{ machine.MachineCategory }}</li>
                  <li class="dot">&bull;</li>
                  <li>{{ machine.MachineType }}</li>
                  <li class="dot">&bull;</li>
                  <li>{{ machine.CustomerID }}</li>
                </ul>
                <h6 v-if="is_admin" style="color: grey">{{ key }}</h6>
              </b-link>
              <div v-if="is_admin">
                <b-button variant="link" @click="cloneMachine(machine)"><font-awesome-icon icon="clone" /> Clone
                  machine</b-button>
                <b-button variant="link" @click="removeMachine(key)"><font-awesome-icon icon="trash" /> Remove
                  machine</b-button>
              </div>
            </b-col>
            <b-col col lg="3" md="12" sm="12" cols="12" class="text-right">
              <!-- <b-col cols="2" md="auto" class="text-right"> -->
              <b-button variant="secondary" :to="'/machine/' + key">{{
                strings.showdetails
              }}</b-button>
            </b-col>
          </b-row>
        </b-card-body>
        <!-- </b-link> -->
      </b-card>
    </template>
  </b-container>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import router from "../router";

export default {
  name: "Machines",
  data() {
    return {};
  },
  computed: {
    machineCategory: {
      get() {
        return this.$store.state.machines.machineCategory;
      },
      set(value) {
        this.$store.commit("machines/machineCategory", value);
      },
    },
    machineCustomer: {
      get() {
        return this.$store.state.machines.machineCustomer;
      },
      set(value) {
        this.$store.commit("machines/machineCustomer", value);
      },
    },
    machineType: {
      get() {
        return this.$store.state.machines.machineType;
      },
      set(value) {
        this.$store.commit("machines/machineType", value);
      },
    },

    breadcrumb() {
      return [{ text: "Machines", to: "/" }];
    },
    ...mapGetters("machines", [
      "filtered",
      "categories",
      "types",
      "customers",
      "image_path",
    ]),
    ...mapGetters("auth", ["is_admin"]),
    ...mapState(["strings", "loading"]),
  },
  methods: {
    ...mapActions("machines", [
      "add_machine",
      "clone_machine",
      "remove_machine",
    ]),

    addMachine() {
      this.add_machine().then((machineKey) =>
        router.push({ path: "/machine/" + machineKey })
      );
    },
    cloneMachine(machine) {
      this.clone_machine(machine).then((machineKey) =>
        router.push({ path: "/machine/" + machineKey })
      );
    },
    removeMachine(machineKey) {
      if (confirm("Are you sure you want to remove this machine?"))
        this.remove_machine(machineKey);
    },
    filterByCustomer(value) {
      this.$store.commit("machines/machineCustomer", value);
    },
    filterByType(value) {
      this.$store.commit("machines/machineType", value);
    },
    filterByCategory(value) {
      this.$store.commit("machines/machineCategory", value);
    },

    // machineVisible(machine) {
    //   return (machine.Visibility == 0 || this.is_admin) &&
    //     (!this.machineCategory || machine.MachineCategory == this.machineCategory) &&
    //     (!this.machineCustomer || machine.CustomerID == this.machineCustomer) &&
    //     (!this.machineType || machine.MachineType == this.machineType)

    // }
  },
  mounted() { },
};
</script>
