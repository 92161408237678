import axios from 'axios'
import router from '../router';

const auth = {
  namespaced: true,

  state: () => ({ 
    login_error_message: '',
    token: localStorage.getItem('token') || '',
    user: null
  }),
  getters: {
    is_logged_in: state => !!state.token && !!state.user,
    is_admin: (state, getters) => getters.is_logged_in && state.user != null && (state.user.roles.includes('editor') || state.user.roles.includes('administrator')),
  },
  mutations: {
    login_error_message(state, message) {
      state.login_error_message = message
    },
    user(state, user) {
      state.user = user
      if (state.user == null) router.replace('/login')
    },
    token(state, token) {
      if (token != '')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      else
        axios.defaults.headers.common['Authorization'] = '';

      localStorage.setItem('token', token)
      state.token = token
    },
  },
  actions: {
    validate({dispatch, commit, state}) {
      //only validate when we have a token
      if (state.token != '') {
        //set token as Authorization header
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token;
        //validate on server
        return axios.post('/wp-json/jwt-auth/v1/token/validate')
          .then((response) => {
            if (response && response.status == 200) {
              dispatch('get_user')
            }
          })
          .catch(() => commit('token', ''))
      }
    },
    login({dispatch, commit}, {username, password}) {
      return axios.post('/wp-json/jwt-auth/v1/token', { username: username, password: password})
        .then((response) => {
          if (response && response.data) {
            if (response.status == 200) {
              commit('token', response.data.token)
              dispatch('get_user')
            }
          }
        })
        .catch(
          () =>  {
            commit('login_error_message', 'Invalid username and/or password')
            commit('token', '')
          }
        )
    },
    logout({commit}) {
      return axios.post('/wp-json/wp/v2/logout')
        .then(() => {
          commit('token', '')
          commit('user', null)
        })
        .catch(() => {
          commit('token', '')
          commit('user', null)
        })
    },
    get_user({ commit }) {
      return axios.get('/wp-json/wp/v2/users/me')
        .then((response) => {
          if (response && response.data) {
            if (response.status == 200) {
              commit('user', response.data)
            }
          }
        })
        .catch(() =>  { commit('user', null) })
    }
  },
}

export default auth