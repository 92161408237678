<template>
  <div id="app">
    <header class="header">
      <navbar></navbar>
      <b-alert v-if="is_logged_in" show dismissible variant="info">{{strings.bugalert}} <a href="mailto:spareparts@atdmachinery.nl">spareparts@atdmachinery.nl</a></b-alert>
    </header>
    <main :id="lowerCaseAndReplace($route.name)">
      <router-view :key="$route.path" />
    </main>
    <footer class="footer">
      <b-container fluid>
        <b-row>
          <b-col>
            &copy; {{ new Date().getFullYear() }} ATD Machinery 
            <b-link v-for="menu in menus['footer-menu']" :key="menu.id" :to="'/'+menu.slug">{{menu.content}}</b-link>
          </b-col>
          
          <b-col class="text-center"><b-link href="mailto:spareparts@atdmachinery.cnl">spareparts@atdmachinery.nl</b-link></b-col>

          <b-col></b-col>
        </b-row>
        
      </b-container>
    </footer>

    <b-modal v-if="unread_news.length && !is_admin" :visible="unread_news.length > 0" ref="news-modal" centered>
      <!-- header -->
      <template #modal-header>
        <b-img-lazy fluid :src="unread_news[0].large_featured_image_url" />
      </template>

      <!-- body -->
      <template #default>
        <h3 class="title" v-html="unread_news[0].title.rendered"></h3>
        <h5 class="subtitle">{{unread_news[0].date | formatDate}}</h5>
        <div v-html="unread_news[0].excerpt.rendered" />
      </template>

      <!-- footer -->
      <template #modal-footer="{ cancel }">
        <b-button variant="primary" :to="'/news/' + unread_news[0].slug" @click="cancel()">
          {{strings.readmore}}
        </b-button>
        <b-button variant="link" @click="cancel()">
          {{strings.close}}
        </b-button>
      </template>
    </b-modal>

  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
      unread_news: []
    }
  },
  computed: {
    ...mapState(['loading', 'menus', 'strings', 'lang']),
    ...mapGetters('auth', ['is_logged_in', 'is_admin']),
  },
  watch: {
    is_logged_in() {
      this.$store.dispatch('get_state').then(() => {
        this.unread_news = this.$store.state.news.list.filter((article) => !article.read)
      })
      this.$store.dispatch('orders/get_current')
    }
  },
  methods: {
    lowerCaseAndReplace: function (a) {
        return a.toLowerCase().replace(/\s/g, "");
    }
  },
  mounted() {
    this.$store.dispatch('set_language', 'en')
  },
}
</script>