<template>
  <b-container>
    <b-img src="../assets/atd-emblem-outline.svg" class="emblem" alt="ATD Machinery"></b-img>
    <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
    <h1>{{strings.rfq}}</h1>

    <b-alert v-model="showResponse" :variant="responseVariant" dismissible>
      {{ responseMessage }}
    </b-alert>

    <template v-if="current && current.order_items">
      <!-- order items -->
      <template>
        <b-row v-if="current.order_items.length != 0">
          <b-col cols="2">
            <div class="cart-label">Machine</div>
          </b-col>
          <b-col cols="2">
            <div class="cart-label">{{strings.partno}}</div>
          </b-col>
          <b-col cols="5">
            <div class="cart-label">{{strings.description}}</div>
          </b-col>
          <b-col>
            <div class="cart-label">{{strings.amount}}</div>
          </b-col>
        </b-row>

        <b-card v-for="(item, key) in current.order_items" :key="key" no-body class="card-cart">
          <b-card-body>
            <b-row align-v="center">
              <b-col cols="2">
                <b-card-text>{{ item.machinedescription }}</b-card-text>
              </b-col>
              <b-col cols="2">
                <b-card-text>{{ item.partno }}</b-card-text>
              </b-col>
              <b-col cols="5">
                <b-card-title>{{ item.description }}</b-card-title>
              </b-col>
              <b-col>
                <b-form-input type="number" v-model="item.quantity" @blur="save_cart" :disabled="loading"></b-form-input>
              </b-col>
              <b-col class="text-right">
                <b-button @click="remove_item(key)" :disabled="loading"><font-awesome-icon icon="trash" /></b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-form v-if="current.order_items.length > 0">
          <b-form-group label="Remarks" class="mr-2 mb-2">
            <b-form-textarea v-model="current.order_remarks" debounce="500" rows="3" max-rows="5" @blur="save_cart" :disabled="loading"></b-form-textarea>
          </b-form-group>
        </b-form>
        
        <p v-if="current.order_items.length == 0">{{strings.emptycart}}</p>
      </template>

      <b-button class="mt-4" :disabled="current.order_items.length == 0 || loading" @click="send" :class="{'button-loading': loading}">{{strings.send}}</b-button>
    </template>

  </b-container>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: "Cart",
  data() {
    return {
      showResponse: false,
      responseVariant: 'success',
      responseMessage: ''
    }
  },
  computed: {
    breadcrumb() {
      return [{text: 'RFQ', to: '/cart'}]
    },
    ...mapState(['loading', 'strings']),
    ...mapState('orders', ['current'])
  },
  methods: {
    ...mapActions('orders', ['save_cart', 'place_order']),

    remove_item(idx) {
      this.current.order_items.splice(idx, 1)
      this.save_cart()
    },

    send() {
      this.place_order().then((response) => { 
        if (response.status == 200 || response.status == 201) {
          this.showResponse = true
          this.responseVariant = "success"
          this.responseMessage = this.strings.orderplaced
        } else {
          this.showResponse = true
          this.responseVariant = "danger"
          this.responseMessage = this.strings.orderplacederror
        }
      })
    }
  },
  updated() {
  },
  mounted() {
  },
}
</script>