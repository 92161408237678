<template>
  <div v-if="machine">
    <!-- <b-embed :key="cortonaFile" v-if="large" type="iframe" aspect="16by9" :src="cortonaFile" allowfullscreen></b-embed> -->
    <b-container>
      <b-img
        src="../assets/atd-emblem-outline.svg"
        class="emblem"
        alt="ATD Machinery"
      ></b-img>
      <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      <h1 class="title">
        {{ machine.MachineDescription }}: Interactive Parts Catalog
      </h1>
      <h5 class="subtitle">{{ machine.MachineCategory }}</h5>

      <b-embed
        :key="cortonaFile"
        type="iframe"
        aspect="4by3"
        :class="{ fullscreen: large }"
        :src="cortonaFile"
        allowfullscreen
      ></b-embed>
    </b-container>
    <b-container>
      <b-row class="mt-5">
        <b-col cols="10" lg="4">
          <template v-if="Object.keys(this.searchdata).length > 0">
            <b-form-group :label="strings.search">
              <b-form-input
                list="search-list"
                v-model="searchPart"
              ></b-form-input>
              <datalist id="search-list">
                <option
                  v-for="part in Object.keys(this.searchdata)"
                  :key="part"
                  >{{ part }}</option
                >
              </datalist>
            </b-form-group>
          </template>

          <div v-if="searchPart != ''">
            <div v-for="result in searchdata[searchPart]" :key="result">
              <div class="btn btn-primary" @click="setSearchResultFile(result)">
                {{ result }}
              </div>
            </div>
          </div>
          <div
            class="btn btn-primary"
            v-if="cortonaFile != pub_path(machine.Cortonalink)"
            @click="setOriginalCortonaFile()"
          >
            Show main assembly
          </div>
        </b-col>
        <b-col class="text-right">
          <b-button v-if="!large" variant="link" @click="large = true"
            ><font-awesome-icon icon="expand-alt" /> Show fullscreen</b-button
          >
          <b-button
            class="btn-exit-fullscreen"
            v-else
            variant="link"
            @click="large = false"
            ><font-awesome-icon icon="expand-alt" /> Exit fullscreen</b-button
          >
        </b-col>
        <b-col cols="12">
          <b-button class="mt-2" :to="'/machine/' + machineKey">{{
            strings.backtomachines
          }}</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Machine",
  data() {
    return {
      large: false,
      searchPart: "",
      cortonaFile: "",
      searchdata: {},
    };
  },
  computed: {
    machineKey() {
      return this.$route.params.slug;
    },
    breadcrumb() {
      return [
        { text: "Machines", to: "/" },
        {
          text: this.machine.MachineDescription,
          to: "/machine/" + this.machineKey,
        },
        {
          text: "Interactive parts catalog",
          to: "/machine/" + this.machineKey + "/partscatalog",
        },
      ];
    },
    machine: function() {
      return this.machines[this.machineKey];
    },
    ...mapGetters("machines", [
      "machines",
      "image_path",
      "download_path",
      "pub_path",
      "download_categories",
    ]),
    ...mapState(["strings"]),
  },
  methods: {
    setOriginalCortonaFile() {
      this.cortonaFile = this.pub_path(this.machine.Cortonalink);
    },
    setSearchResultFile(file) {
      this.cortonaFile = this.pub_path(
        this.machine.Cortonalink.replace(
          /(.*)external_(.*).htm/,
          "$1external_" + file + ".htm"
        )
      );
    },
  },
  updated() {},
  mounted() {
    this.setOriginalCortonaFile();

    if (this.machine.Searchfilelink != "") {
      axios.get(this.pub_path(this.machine.Searchfilelink)).then((result) => {
        this.searchdata = result.data;
        console.log(result.data);
      });
    }
  },
};
</script>
