<template>
  <b-container v-if="machine">
    <b-img
      src="../assets/atd-emblem-outline.svg"
      class="emblem"
      alt="ATD Machinery"
    ></b-img>
    <b-breadcrumb :items="breadcrumb"></b-breadcrumb>

    <template v-if="!edit">
      <b-row>
        <b-col lg="6" md="12" sm="12" cols="12">
          <div class="intro">
            <h1 class="title">{{ machine.MachineDescription }}</h1>
            <h5 class="subtitle">
              {{ machine.MachineCategory }} - {{ machine.MachineType }} -
              {{ machine.CustomerID }}
            </h5>
            <h6 v-if="is_admin" style="color: grey">{{ machineKey }}</h6>

            <b-img
              class="mb-4"
              :alt="machine.MachineDescription"
              :src="image_path(machine.Image)"
              fluid
            />

            <b-button
              :disabled="
                machine.Cortonalink == '' || machine.Cortonalink == undefined
              "
              class="mb-4"
              variant="primary"
              :to="'/machine/' + machineKey + '/partscatalog'"
              >{{ strings.opencatalog }}</b-button
            >
            <p
              v-if="
                is_admin &&
                  (machine.Cortonalink == '' ||
                    machine.Cortonalink == undefined)
              "
            >
              Ongeldige cortona-link: {{ machine.Cortonalink }}
            </p>

            <p v-html="machine.TechSpec"></p>
          </div>
        </b-col>

        <b-col lg="6" md="12" sm="12" cols="12">
          <div class="downloads">
            <h2>{{ strings.downloads }}</h2>
            <div v-if="machine.Downloads">
              <!-- downloads op basis van de 'categorieen' uit de Download_Categories.JSON -->
              <template v-for="dl_cat in download_categories">
                <template
                  v-if="Object.keys(downloads_in_category(dl_cat)).length > 0"
                >
                  <div :key="dl_cat">
                    <b-button
                      block
                      v-b-toggle="['accordion-' + dl_cat]"
                      variant="primary"
                      >{{ dl_cat }}</b-button
                    >
                    <b-collapse
                      :id="'accordion-' + dl_cat"
                      accordion="dl-accordion"
                      role="tabpanel"
                    >
                      <template
                        v-for="(file, idx) in downloads_in_category(dl_cat)"
                      >
                        <b-card
                          :key="'dl-' + dl_cat + '-' + idx"
                          v-if="file.File != null && file.File != ''"
                          class="card-download"
                        >
                          <b-link @click="download_file(file.File)">
                            <b-card-body>
                              <b-row align-v="center">
                                <b-col col lg="8" md="12" sm="12" cols="8">
                                  <b-card-title
                                    v-html="file.Description"
                                  ></b-card-title>
                                  <b-card-sub-title
                                    v-html="file.Category"
                                  ></b-card-sub-title>
                                </b-col>
                                <b-col class="text-right">
                                  <b-spinner
                                    v-if="downloadingFile == file.File"
                                  />
                                  <b-button v-else variant="primary">{{
                                    strings.download
                                  }}</b-button>
                                </b-col>
                              </b-row>
                            </b-card-body>
                          </b-link>
                        </b-card>
                      </template>
                    </b-collapse>
                    <hr />
                  </div>
                </template>
              </template>

              <!-- alle overige downloads met een categorie die niet voorkomt in Download_Categories.JSON of een lege categorie -->
              <template
                v-for="dl_cat in create_unique_list(
                  machine.Downloads,
                  'Category'
                )"
              >
                <template v-if="!download_categories.includes(dl_cat)">
                  <div :key="dl_cat">
                    <b-button
                      block
                      v-b-toggle="['accordion-' + dl_cat]"
                      variant="primary"
                      >{{ dl_cat }}</b-button
                    >
                    <b-collapse
                      :id="'accordion-' + dl_cat"
                      accordion="dl-accordion"
                      role="tabpanel"
                    >
                      <template
                        v-for="(file, idx) in downloads_in_category(dl_cat)"
                      >
                        <b-card
                          :key="'dl-' + dl_cat + '-' + idx"
                          v-if="file.File != null && file.File != ''"
                          class="card-download"
                        >
                          <b-link @click="download_file(file.File)">
                            <b-card-body>
                              <b-row align-v="center">
                                <b-col col lg="8" md="12" sm="12" cols="8">
                                  <b-card-title
                                    v-html="file.Description"
                                  ></b-card-title>
                                  <b-card-sub-title
                                    v-html="file.Category"
                                  ></b-card-sub-title>
                                </b-col>
                                <b-col class="text-right">
                                  <b-spinner
                                    v-if="downloadingFile == file.File"
                                  />
                                  <b-button v-else variant="primary">{{
                                    strings.download
                                  }}</b-button>
                                </b-col>
                              </b-row>
                            </b-card-body>
                          </b-link>
                        </b-card>
                      </template>
                    </b-collapse>
                    <hr />
                  </div>
                </template>
              </template>
            </div>
            <div v-else>{{ strings.nodownloads }}</div>
          </div>
        </b-col>
      </b-row>

      <b-button class="mr-3" to="/">{{ strings.backtomachines }}</b-button>
      <b-button variant="link" v-if="is_admin" @click="edit = !edit">{{
        strings.editmachine
      }}</b-button>
    </template>

    <!-- EDITFORM -->
    <template v-else>
      <b-form>
        <b-form-group label="MachineDescription">
          <b-form-input
            v-model="machine.MachineDescription"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="MachineCategory">
          <b-form-select
            v-model="machine.MachineCategory"
            :options="categories"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="MachineType">
          <b-form-select
            v-model="machine.MachineType"
            :options="types"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="CustomerID">
          <b-form-select
            v-model="machine.CustomerID"
            :options="customers"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="TechSpec">
          <b-form-textarea
            v-model="machine.TechSpec"
            debounce="500"
            rows="3"
            max-rows="5"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="Image">
          Current: {{ machine.Image }}
          <b-form-file
            :disabled="uploadingFile == 'image'"
            ref="image"
            :value="machine.Image"
            @change="handleFileUpload($event.target.files[0], 'image')"
          ></b-form-file>
        </b-form-group>
        <b-form-group label="Cortonalink">
          <b-form-input
            v-model="machine.Cortonalink"
            type="text"
            description="Upload the Cortona files (.htm .xml .wrl etc) with FTP"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Searchfilelink">
          <b-form-input
            v-model="machine.Searchfilelink"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Visibility">
          <b-form-checkbox
            id="visible"
            v-model="machine.Visibility"
            value="1"
            unchecked-value="0"
            >Show this machine</b-form-checkbox
          >
        </b-form-group>

        <b-form-group label="Downloads">
          <b-row>
            <b-col><strong>Category</strong></b-col>
            <b-col cols="3"><strong>File</strong></b-col>
            <b-col><strong>Description</strong></b-col>
          </b-row>
          <template v-for="(file, idx) in machine.Downloads">
            <b-input-group :prepend="idx" :key="idx" class="mt-3">
              <b-form-select
                v-model="file.Category"
                :options="
                  download_categories
                    .concat(create_unique_list(machine.Downloads, 'Category'))
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )
                "
              >
              </b-form-select>

              <b-form-input
                v-if="file.File != null"
                :value="file.File"
                disabled
              ></b-form-input>
              <b-form-file
                placeholder="File"
                v-else
                :disabled="uploadingFile == idx"
                :ref="idx"
                @change="handleFileUpload($event.target.files[0], idx)"
              ></b-form-file>

              <b-form-input
                placeholder="Description"
                :value="file.Description"
                v-model="file.Description"
              ></b-form-input>

              <b-input-group-append>
                <b-button variant="danger" @click="remove_download(idx)"
                  ><font-awesome-icon icon="trash"
                /></b-button>
              </b-input-group-append>
            </b-input-group>
            <em
              :key="'invalid-cat-message' + idx"
              v-if="!download_categories.includes(file.Category)"
            >
              Deze categorie bestaat niet in de Download_Categories.JSON.
            </em>
          </template>

          <hr />
          <b-button @click="add_download()"
            ><font-awesome-icon icon="plus-square" /> Add download</b-button
          >
        </b-form-group>
      </b-form>
      <hr />
      <b-button
        @click="
          edit = !edit;
          get();
        "
        >Cancel</b-button
      >
      <b-button
        @click="
          save();
          edit = !edit;
        "
        >{{ strings.savechanges }}</b-button
      >
      <b-button @click="removeMachine()">Remove machine</b-button>
    </template>
  </b-container>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Vue from "vue";
import axios from "axios";
import fileDownload from "js-file-download";

export default {
  name: "Machine",
  data() {
    return {
      downloadCategory: null,
      edit: false,
      downloadingFile: "",
      uploadingFile: "",
    };
  },
  computed: {
    machineKey() {
      return this.$route.params.slug;
    },
    breadcrumb() {
      return [
        { text: "Machines", to: "/" },
        {
          text: this.machine.MachineDescription,
          to: "/machine/" + this.machineKey,
        },
      ];
    },
    machine: function() {
      return this.machines[this.machineKey];
    },
    // ...mapState('auth', ['user']),
    ...mapGetters("machines", [
      "machines",
      "image_path",
      "download_path",
      "download_categories",
      "categories",
      "types",
      "customers",
    ]),
    ...mapGetters("auth", ["is_admin"]),
    ...mapState(["strings"]),
  },
  methods: {
    ...mapActions("machines", ["save", "get", "remove_machine"]),
    add_download() {
      if (
        Object.prototype.hasOwnProperty.call(this.machine, "Downloads") &&
        Object.keys(this.machine.Downloads).length > 0
      ) {
        var idx = parseInt(Object.keys(this.machine.Downloads).pop()) + 1;
        Vue.set(this.machine.Downloads, idx.toString(), {
          Category: "",
          File: null,
          Description: "",
        });
      } else {
        Vue.set(this.machine, "Downloads", {
          1: { Category: "", File: null, Description: "" },
        });
      }
    },
    remove_download(idx) {
      Vue.delete(this.machine.Downloads, idx);
    },
    removeMachine() {
      if (confirm("Are you sure you want to remove this machine?"))
        this.remove_machine(this.machineKey).then(() => {
          this.$router.go(-1);
        });
    },

    //uploads a file to the server and sets the filename as the download
    handleFileUpload(file, idx) {
      let formData = new FormData();
      this.uploadingFile = idx;
      formData.append("file", file);
      formData.append("image", idx == "image" ? "1" : 0);
      formData.append("lastModified", file.lastModified);
      axios
        .post("/wp-json/wp/v2/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.status == 200) {
            if (idx == "image") {
              Vue.set(this.machine, "Image", file.name);
            } else {
              let fileData = this.machine.Downloads[idx];
              fileData.File = file.name;
              Vue.set(this.machine.Downloads, idx, fileData);
              this.uploadingFile = "";
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 303) {
              if (idx == "image") {
                Vue.set(this.machine, "Image", file.name);
              } else {
                let fileData = this.machine.Downloads[idx];
                fileData.File = file.name;
                Vue.set(this.machine.Downloads, idx, fileData);
                this.uploadingFile = "";
              }
            }
          }
          this.uploadingFile = "";
          alert(error.response.data);
        });
    },

    //makes an authorized request to a (downloadable) file and outputs it to the user
    download_file(file) {
      if (this.downloadingFile == "") {
        this.downloadingFile = file;
        axios
          .get(this.download_path(), {
            responseType: "blob",
            params: { filename: file },
          })
          .then((response) => {
            fileDownload(response.data, file);
            this.downloadingFile = "";
          })
          .catch(() => {
            this.downloadingFile = "";
          });
      }
    },

    downloads_in_category(cat) {
      const ar = Object.entries(this.machine.Downloads);
      const filtered = ar.filter((d) => d[1].Category == cat);
      return Object.fromEntries(filtered);
    },
    create_unique_list(object, prop) {
      var values = [];

      Object.values(object).forEach((m) => {
        if (values.indexOf(m[prop]) < 0 && m[prop] != "") {
          values.push(m[prop]);
        }
      });

      values.sort();
      return values;
    },
  },
  updated() {},
  mounted() {},
};
</script>
