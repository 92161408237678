<template>
  <b-container>
    <b-img src="../assets/atd-emblem-outline.svg" class="emblem" alt="ATD Machinery"></b-img>
    <center><b-img src="../assets/logo.jpg" alt="ATD Machinery" width="200px" class="mb-4"></b-img></center>

    <b-row align-v="center" align-h="center" v-if="!is_logged_in">
      <b-col md="6" lg="4">
        <h1>{{ strings.logintitle }}</h1>
        <b-form @submit="onSubmit" @reset="onReset">
          <b-form-group id="input-group-1" :label="strings.username" label-for="input-1">
            <b-form-input ref="email" id="input-1" v-model="email" type="text" required></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" :label="strings.password" label-for="input-2">
            <b-form-input type="password" id="input-2" v-model="password" required></b-form-input>
          </b-form-group>

          <b-button :href="strings.forgotpasswordurl" target="_blank" variant="link" :disabled="loading" class="mr-4">{{strings.forgotpassword}}</b-button>
          <b-button type="submit" variant="primary" :disabled="loading">{{strings.loginbtn}} <font-awesome-icon v-if="loading" icon="spinner" spin /></b-button>

          <b-alert :show="login_error_message != ''" variant="danger" class="mt-2">{{login_error_message}}</b-alert>
        </b-form>

      </b-col>
      <b-col lg="1"></b-col>
      <b-col md="6" lg="4">
        <h1>{{ strings.noaccounttitle }}</h1>
        <p>{{ strings.noaccountmessage }}</p>
        <b-button to="/register" variant="secondary" :disabled="loading">{{strings.requestaccessbtn}} <font-awesome-icon v-if="loading" icon="spinner" spin /></b-button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

  export default {
    name: "Login",
    data() {
      return {
        email: '',
        password: '',
      }
    },
    computed: {
      ...mapState(['loading', 'strings']),
      ...mapState('auth', ['login_error_message']),
      ...mapGetters('auth', ['is_logged_in'])
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        this.login({'username': this.email, 'password': this.password})
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.email = ''
        this.passord = ''
      },

      ...mapActions('auth', [
        'validate',
        'login'
      ])
    },
    updated() {
      if (this.is_logged_in) {
        if(this.$route.query.nextUrl != null && this.$route.query.nextUrl != '') {
          this.$router.replace(this.$route.query.nextUrl)
        } else {
          this.$router.replace('/')
        }
      }
    },
    mounted() {
      this.$refs.email.focus()
      this.validate()
    },
  }
</script>