import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import cpt from './cpt'
import machines from './machines'
import orders from './orders'
import axios from 'axios'
import router from '../router'
import Page from '../views/Page'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    lang: 'en',
    loading: false,
    skip_cache: false,
    menus: [],
    strings: {"machines":"Machines","news":"News","cart":"Cart","readmore":"Read more","showdetails":"Show details","filterby":"Filter by","close":"Close","logintitle":"Login","loginbtn":"Login","logout":"Logout","username":"Username","password":"Password","emptycart":"Your cart is empty","send":"Send","downloads":"Downloads","backtomachines":"Back to machines","backtonews":"Back to news","description":"Description","partno":"Partnumber","customer":"Customer","category":"Category","type":"Type","new":"New","orderplaced":"Your order has been sent. You will receive a copy of the order in your email.","orderplacederror":"Something went wrong while placing your order. Please try again or contact us.","bugalert":"Feedback can be sent to ","opencatalog":"Open Interactive Parts Catalog","selectfilter":"Select filter","download":"Download","nodownloads":"No downloads available","editmachine":"Edit machine","savechanges":"Save changes","rfq":"Quote request list (RFQ)","prodid":"Prod. ID","amount":"Amount","search":"Search","forgotpassword":"Lost your password?","forgotpasswordurl":"https://clientportal.atdmachinery.nl/lost-password/", noaccounttitle: "No account?", noaccountmessage: "If you want access to our client portal, you can request access via the button below. After your request has been evaluated by the sales department, we will inform you via e-mail.", requestaccessbtn: "Request access", backtologin: "Back to login", accountrequested:"Your account has been requested. We will contact shortly."}
  },
  getters: {
  },
  mutations: {
    lang: (state, val) => state.lang = val,
    loading: (state, val) => state.loading = val,
    menus: (state, val) => state.menus = val,
    strings: (state, val) => state.strings = val,
  },
  actions: {
    get_strings({commit, state}) {
      axios.get('/wp-json/wp/v2/strings?lang='+state.lang)
      .then((response) => {
        commit('strings', response.data)
      })
    },
    get_menus({commit, state}) {
      axios.get('/wp-json/wp/v2/menus?lang='+state.lang)
      .then((response) => {
        commit('menus', response.data)
      })
    },
    get_state({dispatch, commit, state}) {
      return axios.get('/wp-json/wp/v2/state?lang='+state.lang)
      .then((response) => {
        commit('menus', response.data.menus)
        commit('strings', response.data.strings)

        // if (response.data.pages.length >= 0)
          commit('pages/list', {list: response.data.pages })
        
        // if (response.data.posts.length >= 0)
          commit('news/list', { list: response.data.posts })

        commit('machines/list', response.data.machines )
        commit('machines/dlcategories', response.data.dlcategories )
        dispatch('set_page_routes')
        
        return
      })
    },
    set_language({commit, dispatch}, lang) {
      commit('lang', lang)
      dispatch('get_state')
    },
    set_page_routes({state}) {
      const pages = state.pages.list
      const pageById = id => pages.find(p => p.id == id)
      const pathById = function (page) {
        var p = page
        var slug = '/' + page.slug
        while (p.parent != 0) {
          p = pageById(p.parent)
          slug = '/' + p.slug + slug
        }
        return slug
      }
      pages.forEach(page => {
        router.addRoute({
          path: pathById(page),
          name: page.slug,
          component: Page,
          props: { slug: page.slug },
          meta: page
        })
      })
    }
  },
  modules: {
    auth: auth,
    pages: cpt,
    news: cpt,
    machines: machines,
    orders: orders
  }
})

store.commit('news/cpt', 'posts')
store.commit('pages/cpt', 'pages')

export default store