import axios from "axios"
import Vue from "vue"

const machines = {
  namespaced: true,

  state: () => ({
    history: [],
    current: {}
  }),
  getters: {
  },
  mutations: {
    history: (state, value) => state.history = value,
    current: (state, value) => state.current = value,
    add_item: (state, value) => {
      //find existing item in shoppingcart
      var idx = state.current.order_items.findIndex((item) => item.partno == value.dpl_item.partnumber)
      var qty = 0
      //if found, increase quantity
      if (idx > -1) {
        //we dont use parseInt(value.dpl_item.quantity), we always push quantity of 1,, requested by ATD
        qty = parseInt(state.current.order_items[idx].quantity) + 1
        Vue.set(state.current.order_items[idx], 'quantity', qty)
      } 
      //not found, add new record to shoppingcart
      else {
        qty = 1 //parseInt(value.dpl_item.quantity)
        state.current.order_items.push({ machinekey: value.machinekey, machinedescription: value.machinedescription, partno:value.dpl_item.partnumber, description: value.dpl_item.description, quantity: qty, raw: JSON.stringify(value) })
      }
    }
  },
  actions: {
    async get_history({commit}) {
      return axios.get('/wp-json/wp/v2/order')
      .then((response) => {
        if (response && response.status == 200) {
          commit('history', response.data)
        }
      })
      .catch(() => {})
    },
    async get_current({commit, dispatch}) {
      return axios.get('/wp-json/wp/v2/order?status=draft')
      .then((response) => {
        if (response && response.status == 200) {
          //no pending orders found, create a new one
          if (response.data.length == 0) {
            dispatch('create_order')
          //one pending order found, set it as current
          } else if (response.data.length == 1) {
            commit('current', response.data[0])
          //it should never be possible to have multiple pending orders
          } else {
            console.log('Multiple pending orders found. Something went wrong probably')
          }
        }
      })
      .catch(() => {})
    },

    async create_order({commit, rootState}) {
      return axios.post('/wp-json/wp/v2/order', { title: rootState.auth.user.name, status: 'draft', order_items: [] })
      .then((createResponse) => {
        if (createResponse && (createResponse.status == 200 || createResponse.status == 201)) {
          commit('current', createResponse.data)
        }
      })
    },

    async add_item_to_cart({commit, dispatch}, item) {
      commit('add_item', item)
      //TODO: Show better notification that item has been added to cart?
      return dispatch('save_cart')
    },

    async save_cart({commit, state}) {
      return axios.post('/wp-json/wp/v2/order/'+state.current.id, { id: state.current.id, order_items: state.current.order_items, order_remarks: state.current.order_remarks })
      .then((createResponse) => {
        if (createResponse && (createResponse.status == 200 || createResponse.status == 201)) {
          commit('current', createResponse.data)
        }
      })
    },

    async place_order({commit, dispatch, state}) {
      return axios.post('/wp-json/wp/v2/order/'+state.current.id, { id: state.current.id, order_items: state.current.order_items, status: 'publish' })
      .then((createResponse) => {
        if (createResponse && (createResponse.status == 200 || createResponse.status == 201)) {
          // dispatch('get_history')
          commit('current', {})
          dispatch('get_current')
        }
        return createResponse
      })
    }
  },
}

export default machines