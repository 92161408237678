<template>
  <div class="page" :class="page.slug">
    <b-container>
      <b-img src="../assets/atd-emblem-outline.svg" class="emblem" alt="ATD Machinery"></b-img>
      <b-row>
        <b-col cols="8">
          <h1 v-html="page.title.rendered" class="title"></h1>
          <div v-html="page.content.rendered"></div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Page',
  props: {
    slug: {
      required: true,
      type: String
    }
  },
  computed: {
    page: function() { return this.$route.meta },
    ...mapState(['strings'])
  }
}
</script>
