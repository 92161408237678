import axios from 'axios'
import Vue from 'vue'

const cpt = {
  namespaced: true,

  state: () => ({ 
    cpt: '',
    num_pages: 1,
    current_page: 1,
    total_items: 0,
    per_page: 25,
    list: []
  }),
  getters: {
    current_page_list: (state) => {
      // var start = (parseInt(state.current_page) -1) * parseInt(state.per_page)
      // var end = Math.min(state.list.length, start + parseInt(state.per_page))
      // return state.list.slice(start, end)
      return state.list
    },
    item_index_by_item_id: (state) => {
      return state.list.reduce((map, item, index) => {
        map[item.id] = index
        return map
      }, {})
    },
    item_index_by_item_slug: (state) => {
      return state.list.reduce((map, item, index) => {
        map[item.slug] = index
        return map
      }, {})
    },
    item_by_id: (state, getters) => (id) => {
      return state.list[getters.item_index_by_item_id[id]] || false
    },
    item_by_slug: (state, getters) => (slug) => {
      return state.list[getters.item_index_by_item_slug[slug]] || false
    },
  },
  mutations: {
    cpt: (state, data) => state.cpt = data,
    num_pages: (state, data) => state.num_pages = data,
    total_items: (state, data) => state.total_items = data,
    per_page: (state, data) => state.per_page = data,
    current_page: (state, data) => state.current_page = data,
    list: (state, data) =>  {
      state.list = data.list
      // if (state.list.length < state.total_items) {
      //   for (var i = 0; i < state.total_items; i++) {
      //     if (!state.list[i]) {
      //       Vue.set(state.list, i, { id: -1 })
      //     }
      //   }
      // }
      // var idx = (parseInt(data.page) -1) * parseInt(data.per_page)
      // data.list.forEach((item) => {
      //   Vue.set(state.list, idx, item)
      //   idx++
      // })
    },
    item: (state, data) => {
      var idx = state.list.findIndex((item) => item.id == data.id)
      if (idx == -1) state.list.push(data)
      else Vue.set(state.list, idx, data)
    }
  },
  actions: {
    async get({commit, state, rootState }, { ...options } = {}) {
      // page = page || 1
      // per_page = per_page || 10
      // commit('per_page', per_page || 10)      

      let params = new URLSearchParams(options)
      let url = '/wp-json/wp/v2/'+state.cpt+'?per_page='+state.per_page+'&lang='+rootState.lang+'&page='+state.current_page+'&'+params.toString().replaceAll('%2C', ',')
      if (rootState.skip_cache) {
        url += '&skip_cache=1'
      }

      return axios.get(url)
        .then((response) => {
          if (response && response.status == 200) {
            commit('num_pages', response.headers['x-wp-totalpages'])
            commit('total_items', response.headers['x-wp-total'])
            commit('list', { list: response.data })
          }
        })
        .catch(() => {})
    },
    async get_item_by_slug({commit, state, rootState }, slug) {
      let url = '/wp-json/wp/v2/'+state.cpt+'?slug='+slug+'&lang='+rootState.lang
      if (rootState.skip_cache) {
        url += '&skip_cache=1'
      }

      return axios.get(url)
        .then((response) => {
          if (response && response.status == 200) {
            if (response.data.length == 1)
              commit('item', response.data[0])
          }
        })
        .catch(() => {})
    },
    async get_item({commit, state, rootState }, id) {
      let url = '/wp-json/wp/v2/'+state.cpt+'/'+id+'?_embed=author&lang='+rootState.lang
      if (rootState.skip_cache) {
        url += '&skip_cache=1'
      }

      return axios.get(url)
        .then((response) => {
          if (response && response.status == 200) {
            commit('item', response.data)
          }
        })
        .catch(() => {})
    },

    async save_item({commit, state, rootState}, post) {
      let url = '/wp-json/wp/v2/'+state.cpt+'/'+post.id+'?_embed=author&lang='+rootState.lang

      return axios.post(url, post)
        .then((response) => {
          if (response && response.status == 200) {
            commit('item', response.data)
          }
        })
        .catch(() => {})
    }
  }
}

export default cpt