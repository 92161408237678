<template>
  <b-navbar toggleable="lg" v-if="is_logged_in">
    <b-container fluid>
      <b-navbar-brand to="/">
        <b-img src="../assets/logo.jpg" fluid alt="ATD Machinery"></b-img>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item :active="$route.path == '/'" to="/">{{strings.machines}}</b-nav-item>
          <b-nav-item :active="$route.path == '/news'" to="/news">{{strings.news}}</b-nav-item>
          <b-nav-item :active="$route.path == '/'+menu.slug" v-for="menu in menus['main-menu']" :key="menu.id" :to="'/'+menu.slug">{{menu.content}}</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <div class="cart">
            <b-nav-item :active="$route.path == '/cart'" to="/cart" v-if="current && current.order_items">
              <font-awesome-layers>
                <font-awesome-icon icon="shopping-cart"/>
                <font-awesome-layers-text v-if="!loading" counter :value="current.order_items.length" position="top-left" />
                <font-awesome-icon v-else icon="spinner" spin position="top-left" class="mb-3 ml-3" />
              </font-awesome-layers>
              RFQ
            </b-nav-item>
          </div>

          <b-nav-item @click="logout">{{strings.logout}}</b-nav-item>
          <div class="language-selector">
            <b-nav-item :active="lang == 'en'" @click="set_lang('en')">En</b-nav-item>
            <b-nav-item :active="lang == 'es'" @click="set_lang('es')">Es</b-nav-item>
          </div>
          
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  name: "Navbar",
  computed: {
    routes: function() { return this.$router.options.routes.filter((r) => !r.meta?.hide_from_nav) },
    ...mapState({
      user: state => state.auth.user,
      groups: state => state.groups.list
    }),
    ...mapGetters('auth', [
      'is_logged_in'
    ]),
    ...mapState(['loading', 'menus', 'strings', 'lang']),
    ...mapState('orders', ['current']),
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('auth', ['logout']),
    set_lang(lang) {
      this.$store.dispatch('set_language', lang)
    }
  }
}
</script>